import { region_by_sucursal, regiones_chile, orderRegiones } from 'utils/comunas_map_json';

export function processData(data, field_name = '', data_type = null) {
  const series = {};

  data.series.forEach((serie) => {
    if (serie.fields) {
      serie.fields.forEach((field) => {
        if (field.name === field_name) {
          series[serie.refId] = field['values'].map((value) => (data_type === 'json' ? JSON.parse(value) : value));
        }
      });
    }
  });

  return series;
}

export function getServicesByHost(services) {
  const hosts = {};
  if (services && services.length) {
    services.forEach((srv) => {
      const { description, host_name, state } = srv;
      if (!hosts[host_name]) {
        hosts[host_name] = { services: [], totalServices: 0, servicesWithProblems: 0, servicesWithoutProblems: 0 };
      }
      hosts[host_name]['totalServices'] += 1;
      hosts[host_name]['services'].push({ srvName: description, srvState: state });
      if (state !== 0) {
        hosts[host_name]['servicesWithProblems'] += 1;
      } else {
        hosts[host_name]['servicesWithoutProblems'] += 1;
      }
    });
  }
  return hosts;
}

export function getRegionStatus(hosts) {
  const regions = [];
  if (hosts && hosts.length) {
    hosts.forEach((host) => {
      const { custom_variables, groups, name, state } = host;
      if (custom_variables.SUCURSAL) {
        if (state !== 0) {
          if (state > regiones_chile[region_by_sucursal[custom_variables.SUCURSAL]]['status']) {
            regiones_chile[region_by_sucursal[custom_variables.SUCURSAL]]['status'] = 2;
          }
          regiones_chile[region_by_sucursal[custom_variables.SUCURSAL]]['problems'] += 1;
          regiones_chile[region_by_sucursal[custom_variables.SUCURSAL]]['hostProblems'].push(name);
        }
        regiones_chile[region_by_sucursal[custom_variables.SUCURSAL]]['active'] = true;
      }
    });
  }
  orderRegiones.forEach((reg) => {
    regions.push({ ...regiones_chile[reg], region: reg });
  });
  return regions;
}

export function getSucursales(hosts) {
  const sucursales = {};
  const arraySucursales = [];
  if (hosts && hosts.length) {
    hosts.forEach((host) => {
      const { custom_variables, groups, name, state } = host;
      if (custom_variables.SUCURSAL) {
        if (!sucursales[custom_variables.SUCURSAL]) {
          sucursales[custom_variables.SUCURSAL] = [];
        }
        sucursales[custom_variables.SUCURSAL].push({
          host: name,
          hostState: state,
          region: region_by_sucursal[custom_variables.SUCURSAL],
        });
      }
    });
  }
  for (const suc in sucursales) {
    const element = sucursales[suc];
    arraySucursales.push({ hostDetails: element, sucursalName: suc, region: element[0].region });
  }
  return arraySucursales;
}

export function getInfoServiceAreas(hosts, servicesByHost) {
  const arrayAreas = [
    'Call Center',
    'AREA FINANZAS',
    'Seguridad_BD',
    'Servidores',
    'JDE',
    'Areas de Negocio',
    'AREA SALUD',
    'JBOSS',
    'DevOps',
    'Redes',
    'Content Manager',
    'AREA SERVICIO AL CLIENTE',
  ];
  const areas = {};
  if (hosts && hosts.length) {
    hosts.forEach((host) => {
      const { custom_variables, groups, name, state } = host;
      if (custom_variables.AREA) {
        if (!areas[custom_variables.AREA]) {
          areas[custom_variables.AREA] = {
            hosts: [],
            totalSrv: 0,
            servicesWithProblems: 0,
            totalHost: 0,
            hostWithProblems: 0,
          };
        }
        areas[custom_variables.AREA]['totalHost'] += 1;
        areas[custom_variables.AREA]['hosts'].push({ host: name, hostState: state });
        if (state !== 0) {
          areas[custom_variables.AREA]['hostWithProblems'] += 1;
        }
      }
    });
  }
  const arrayAreasResponse = [];
  arrayAreas.forEach((area) => {
    const hostsByArea = areas[area]['hosts'];
    hostsByArea.forEach((host) => {
      const servicesHost = servicesByHost[host.host];
      areas[area]['totalSrv'] += servicesHost['totalServices'];
      areas[area]['servicesWithProblems'] += servicesHost['servicesWithProblems'];
    });
    arrayAreasResponse.push({
      area,
      totalSrv: areas[area]['totalSrv'],
      servicesWithProblems: areas[area]['servicesWithProblems'],
      totalHost: areas[area]['totalHost'],
      hostWithProblems: areas[area]['hostWithProblems'],
    });
  });
  console.log(arrayAreasResponse);
  return arrayAreasResponse;
}
