import React, { useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import GeographicMap from 'components/GeographicMap/GeographicMap';
import image from '../../img/logo.png';
import mapa from '../../img/mapa2.png';

import './generalStatus.css';

interface Props extends PanelProps<SimpleOptions> {}

interface Props {
  options: any;
  data: any;
  width: number;
  height: number;
  regionStatus: any;
  sucursales: any;
  infoServiceAreas: any;
}

const areas = {
  call_center: {
    Nombre: 'Call Center',
    Alarmas_infra: 3,
    Uptime_infra: 95.8,
  },
  area_finanzas: {
    Nombre: 'Área Finanzas',
    Alarmas_infra: 3,
    Uptime_infra: 99.1,
  },
  seguridad_bd: {
    Nombre: 'Seguridad BD',
    Alarmas_infra: 3,
    Uptime_infra: 98.8,
  },
  servidores: {
    Nombre: 'Servidores',
    Alarmas_infra: 3,
    Uptime_infra: 99.3,
  },
  jde: {
    Nombre: 'JDE',
    Alarmas_infra: 3,
    Uptime_infra: 99.5,
  },
  areas_de_negocio: {
    Nombre: 'Áreas De Negocio',
    Alarmas_infra: 3,
    Uptime_infra: 92.8,
  },
  area_salud: {
    Nombre: 'Área Salud',
    Alarmas_infra: 3,
    Uptime_infra: 99.6,
  },
  area_salud_derecha: {
    Nombre: 'Área Salud',
    Alarmas_infra: 3,
    Uptime_infra: 99.8,
  },
  jboss: {
    Nombre: 'JBOSS',
    Alarmas_infra: 3,
    Uptime_infra: 97.8,
  },
  devops: {
    Nombre: 'DevOPS',
    Alarmas_infra: 3,
    Uptime_infra: 98.5,
  },
  redes: {
    Nombre: 'Redes',
    Alarmas_infra: 3,
    Uptime_infra: 99.8,
  },
  content_manager: {
    Nombre: 'Content Manager',
    Alarmas_infra: 3,
    Uptime_infra: 98.3,
  },
  area_servicio_al_cliente: {
    Nombre: 'Área Servicio Al Cliente',
    Alarmas_infra: 3,
    Uptime_infra: 99.8,
  },
};

export const GeneralStatusPanel: React.FC<Props> = ({
  options,
  data,
  width,
  height,
  regionStatus,
  sucursales,
  infoServiceAreas,
}) => {
  const [expandedAreas, setExpandedAreas] = useState({});
  const [scale, setScale] = useState<any>(1.25);
  const [selectedRegion, setSelectedRegion] = useState('');

  const toggleAreaDetails = (id) => {
    setExpandedAreas((prevExpandedAreas) => ({
      ...prevExpandedAreas,
      [id]: !prevExpandedAreas[id],
    }));
  };
  function resizeSection(type: string) {
    let newScale = scale;
    if (type === 'in') {
      newScale += 0.5;
    } else if (type === 'out') {
      newScale -= 0.5;
    }
    setScale(newScale);
  }
  function getSucursales() {
    // console.log(sucursales);
    const selectedSucursales = [];
    sucursales.forEach((suc: any) => {
      if (selectedRegion) {
        if (suc.region === selectedRegion) {
          selectedSucursales.push(suc);
        }
      }
    });
    return selectedSucursales.map((suc: any, index: number) => (
      <div className="sucursal-item" key={`sucursal-item-${index}`}>
        <div className="sucursal-indicator"></div>
        <div className="sucursal-info">
          <div className="sucursal-nombre">{suc.sucursalName}</div>
          <div className="sucursal-red-info">
            <div className="sucursal-red">Nombre de red</div>
            <div className="sucursal-velodicad-red">200 mb/s </div>
            <div className="sucursal-estado success">OK</div>
          </div>
          <div className="sucursal-red-info">
            <div className="sucursal-red">Nombre de red</div>
            <div className="sucursal-velodicad-red">200 mb/s </div>
            <div className="sucursal-estado success">OK</div>
          </div>
        </div>
      </div>
    ));
  }
  // console.log(selectedRegion);

  return (
    <div className="body">
      <div className="columna back1">
        <div className="columna-interior mapa">
          <div className="minor-box">
            <img src={image} alt="Logo de Consalud" />
          </div>
          {/* <div className='big-box'> <img src={mapa} alt="" /></div> */}
          <div className="big-box">
            <div className="actions">
              <div className="click" onClick={() => resizeSection('in')}>
                +
              </div>
              <div className="click" onClick={() => resizeSection('out')}>
                -
              </div>
            </div>
            <GeographicMap
              chooseRegion={setSelectedRegion}
              regiones={regionStatus}
              info={''}
              regionNumber={[]}
              region={''}
              scale={scale}
            />
          </div>
        </div>
        <div className="columna-interior sucursales">
          <div className="sucursal-titulo">Sucursales</div>
          <div className="sucursal-lista">{sucursales && sucursales.length ? getSucursales() : null}</div>
        </div>
      </div>
      <div className="columna back2">
        <div className="columna-interior">
          <div className="servicios-areas-titulo">Servicios/Areas</div>
          <div className="servicios-areas-container">
            {/* {Object.entries(areas).map(([id, area]) => ( */}
            {infoServiceAreas.map((area, id) => {
              let upPercentage;
              let percentage = ((area.totalHost - area.hostWithProblems) * 100) / area.totalHost;
              if (Number.isInteger(percentage)) {
                upPercentage = percentage.toString(); // Si es un entero, devuelve el número como una cadena sin decimales
              } else {
                upPercentage = percentage.toFixed(2); // Si tiene decimales, los formatea con la cantidad deseada de decimales
              }
              return (
                <div
                  className="servicios-areas-item"
                  id={id}
                  key={id}
                  style={{ height: expandedAreas[id] ? 'auto' : '18%' }}
                >
                  <div className="servicios-areas-head" onClick={() => toggleAreaDetails(id)}>
                    <div className="servicios-titulo-container">
                      <div className="servicios-areas-titulo-item">
                        {area.area}
                        <span>
                          <svg
                            className={expandedAreas[id] ? 'svg-rotate-up' : 'svg-rotate-down'}
                            width="15"
                            height="9"
                            viewBox="0 0 15 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8.21713 8.21663C7.93588 8.49753 7.55463 8.65531 7.15713 8.65531C6.75963 8.65531 6.37838 8.49753 6.09713 8.21663L0.439127 2.56063C0.157865 2.27923 -9.37265e-05 1.89763 4.17235e-08 1.49977C9.38099e-05 1.10192 0.158232 0.72039 0.439627 0.439127C0.721021 0.157865 1.10262 -9.37265e-05 1.50048 4.17234e-08C1.89834 9.38099e-05 2.27986 0.158233 2.56113 0.439627L7.15713 5.03563L11.7531 0.439627C12.0359 0.166254 12.4147 0.0148813 12.808 0.0181122C13.2013 0.0213432 13.5776 0.178919 13.8559 0.456901C14.1341 0.734883 14.292 1.11103 14.2956 1.50432C14.2993 1.89762 14.1482 2.2766 13.8751 2.55963L8.21813 8.21763L8.21713 8.21663Z"
                              fill="#A0A1A5"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="servicio-areas-titulo-sub success">INF</div>
                    </div>
                    <div className="servicio-disponibilidad-container">
                      <div className="servicio-disponibilidad-texto-percentage">
                        <span className="servicio-disponibilidad-percentage">{upPercentage}%</span>
                        <div className="servicio-disponibilidad-text">Disponibilidad</div>
                      </div>
                      <div className="servicio-disponibilidad-indicator success"></div>
                    </div>
                  </div>
                  <div className={`servicio-detalles ${expandedAreas[id] ? 'expanded' : 'collapsed'}`}>
                    <div className="card-divider"></div>
                    <div className="servicio-detalles-item titulo-size">
                      <span className="servicio-detalles-estado-titulo">Estado</span>
                      <span className="servicio-detalles-alarma">Alarmas</span>
                      <span className="servicio-detalles-up">Up</span>
                    </div>
                    <div className="servicio-detalles-item">
                      <span className="servicio-detalles-estado">Infraestructura</span>
                      <span className="servicio-detalles-alarma">{area.servicesWithProblems}</span>
                      <span className="servicio-detalles-up">{upPercentage}%</span>
                    </div>
                    {/* ... otras líneas de detalles ... */}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="datacenter-titulo">Datacenter</div>
          <div className="datacenter">
            <div className="datacenter-item">
              <div className="servicio-indicador-header">
                <div className="servicio-areas-titulo-item">Datacenter Teatinos</div>
                <div className="servicio-disponibilidad-container">
                  <div className="servicio-disponibilidad-texto-percentage">
                    <span className="servicio-disponibilidad-percentage">99,8%</span>
                    <div className="servicio-disponibilidad-text">Disponibilidad</div>
                  </div>
                  <div className="servicio-disponibilidad-indicator"></div>
                </div>
              </div>
              <div className="servicio-indicador-footer">
                <div className="servicio-indicadores">
                  <div className="servicio-indicador success">
                    <div className="servicio-titulo-estado">
                      <span className="servicio-nombre">Wireless</span>
                      <span className="servicio-estado">OK</span>
                    </div>
                    <div className="servicio-velocidad">200 mb/s</div>
                  </div>

                  <div className="servicio-indicador warning">
                    <div className="servicio-titulo-estado">
                      <span className="servicio-nombre">Router</span>
                      <span className="servicio-estado">Warning</span>
                    </div>
                    <div className="servicio-velocidad">200 mb/s</div>
                  </div>
                  <div className="servicio-indicador critical">
                    <div className="servicio-titulo-estado">
                      <span className="servicio-nombre">Firewall</span>
                      <span className="servicio-estado ">Critical</span>
                    </div>
                    <div className="servicio-velocidad">200 mb/s</div>
                  </div>
                  <div className="servicio-indicador warning">
                    <div className="servicio-titulo-estado">
                      <span className="servicio-nombre">Switch</span>
                      <span className="servicio-estado">Warning</span>
                    </div>
                    <div className="servicio-velocidad">200 mb/s</div>
                  </div>
                  <div className="servicio-indicador critical">
                    <div className="servicio-titulo-estado">
                      <span className="servicio-nombre">Enlaces</span>
                      <span className="servicio-estado ">Critical</span>
                    </div>
                    <div className="servicio-velocidad">200 mb/s</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="datacenter-item">
              <div className="servicio-indicador-header">
                <div className="servicio-areas-titulo-item">Datacenter Quilicura</div>
                <div className="servicio-disponibilidad-container">
                  <div className="servicio-disponibilidad-texto-percentage">
                    <span className="servicio-disponibilidad-percentage">99,8%</span>
                    <div className="servicio-disponibilidad-text">Disponibilidad</div>
                  </div>
                  <div className="servicio-disponibilidad-indicator"></div>
                </div>
              </div>

              <div className="servicio-indicador-footer">
                <div className="servicio-indicadores">
                  <div className="servicio-indicador success">
                    <div className="servicio-titulo-estado">
                      <span className="servicio-nombre">Wireless</span>
                      <span className="servicio-estado">OK</span>
                    </div>
                    <div className="servicio-velocidad">200 mb/s</div>
                  </div>

                  <div className="servicio-indicador warning">
                    <div className="servicio-titulo-estado">
                      <span className="servicio-nombre">Router</span>
                      <span className="servicio-estado">Warning</span>
                    </div>
                    <div className="servicio-velocidad">200 mb/s</div>
                  </div>
                  <div className="servicio-indicador critical">
                    <div className="servicio-titulo-estado">
                      <span className="servicio-nombre">Firewall</span>
                      <span className="servicio-estado ">Critical</span>
                    </div>
                    <div className="servicio-velocidad">200 mb/s</div>
                  </div>
                  <div className="servicio-indicador warning">
                    <div className="servicio-titulo-estado">
                      <span className="servicio-nombre">Switch</span>
                      <span className="servicio-estado">Warning</span>
                    </div>
                    <div className="servicio-velocidad">200 mb/s</div>
                  </div>
                  <div className="servicio-indicador critical">
                    <div className="servicio-titulo-estado">
                      <span className="servicio-nombre">Enlaces</span>
                      <span className="servicio-estado ">Critical</span>
                    </div>
                    <div className="servicio-velocidad">200 mb/s</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralStatusPanel;

<div className="columna color2">
  <div className="columna-interior">
    <div className="servicios-areas">
      <div>Servicios/Areas</div>
      <div className="servicio-area-item">
        <div className="area-titulo">Call center</div>
        <div className="disponibilidad">95,8% Disponibilidad</div>
      </div>
      <div className="servicio-area-item">
        <div className="area-titulo">Call center</div>
        <div className="disponibilidad">95,8% Disponibilidad</div>
      </div>
      <div className="servicio-area-item">
        <div className="area-titulo">Call center</div>
        <div className="disponibilidad">95,8% Disponibilidad</div>
      </div>
    </div>
    <div className="datacenter-titulo">Datacenter</div>
    <div className="datacenter">
      <div className="datacenter-item">
        <div className="datacenter-nombre">Datacenter Teatinos</div>
      </div>

      <div className="datacenter-item">
        <div className="datacenter-nombre">Datacenter Quilicura</div>
      </div>
    </div>
  </div>
</div>;
