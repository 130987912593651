const region_by_sucursal = {
  C_BUPA: 'Region_Metropolitana_de_Santiago',
  ANTOFAGASTA: 'Antofagasta',
  ARICA: 'Arica_y_Parinacota',
  CHILLAN: 'Bio-Bio',
  CURICO: 'Maule',
  HUERFANOS: 'Region_Metropolitana_de_Santiago',
  Iquique: 'Tarapaca',
  IQUIQUE: 'Tarapaca',
  LA_SERENA: 'Coquimbo',
  LOS_ANGELES: 'Bio-Bio',
  MAIPU: 'Region_Metropolitana_de_Santiago',
  MELIPILLA: 'Region_Metropolitana_de_Santiago',
  PUENTE_ALTO: 'Region_Metropolitana_de_Santiago',
  PUNTA_ARENAS: 'Magallanes_y_Antartica_Chilena',
  QUILLOTA: 'Valparaiso',
  RANCAGUA: 'Libertador_General_Bernardo_OHiggins',
  SAN_ANTONIO: 'Valparaiso',
  SAN_BERNARDO: 'Region_Metropolitana_de_Santiago',
  TALCAHUANO: 'Bio-Bio',
  VALDIVIA: 'Los_Rios',
  'Vina del Mar': 'Valparaiso',
  COPIAPO: 'Atacama',
  Quilicura: 'Region_Metropolitana_de_Santiago',
  CALAMA: 'Antofagasta',
  COYHAIQUE: 'Aisen_del_General_Carlos_Ibanez_del_Campo',
  SAN_MIGUEL: 'Region_Metropolitana_de_Santiago',
  AGUSTINAS: 'Region_Metropolitana_de_Santiago',
  C_Vespucio: 'Region_Metropolitana_de_Santiago',
  CASTRO: 'Los_Lagos',
  C_Bicentenario: 'Region_Metropolitana_de_Santiago',
  C_Indisa: 'Region_Metropolitana_de_Santiago',
  LOS_ANDES: 'Valparaiso',
  TEMUCO: 'La_Araucania',
  OSORNO: 'Los_Lagos',
  QUILPUE: 'Valparaiso',
  Providencia: 'Region_Metropolitana_de_Santiago',
  PROVIDENCIA: 'Region_Metropolitana_de_Santiago',
  DC_TEATINOS: 'Region_Metropolitana_de_Santiago',
  SAN_FELIPE: 'Valparaiso',
  TALAGANTE: 'Region_Metropolitana_de_Santiago',
  C_Tabancura: 'Region_Metropolitana_de_Santiago',
  TALCA: 'Maule',
  CONCEPCION: 'Bio-Bio',
  PUERTO_MONTT: 'Los_Lagos',
  PUERTO_VARAS: 'Los_Lagos',
  'Rosario Norte': 'Region_Metropolitana_de_Santiago',
  ROSARIO_NORTE: 'Region_Metropolitana_de_Santiago',
  VIÑA_DEL_MAR: 'Valparaiso',
  VALLENAR: 'Atacama',
  CONSTITUCION: 'Maule',
  VALPARAISO: 'Valparaiso',
};

const regiones_chile = {
  Arica_y_Parinacota: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  Tarapaca: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  Antofagasta: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  Atacama: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  Coquimbo: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  Valparaiso: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  Region_Metropolitana_de_Santiago: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  Libertador_General_Bernardo_OHiggins: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  Maule: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  'Bio-Bio': {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  La_Araucania: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  Los_Rios: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  Los_Lagos: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  Aisen_del_General_Carlos_Ibanez_del_Campo: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
  Magallanes_y_Antartica_Chilena: {
    status: 0,
    hostProblems: [],
    problems: 0,
    active: false,
  },
};

const orderRegiones = [
  'Arica_y_Parinacota',
  'Tarapaca',
  'Antofagasta',
  'Atacama',
  'Coquimbo',
  'Valparaiso',
  'Region_Metropolitana_de_Santiago',
  'Libertador_General_Bernardo_OHiggins',
  'Maule',
  'Bio-Bio',
  'La_Araucania',
  'Los_Rios',
  'Los_Lagos',
  'Aisen_del_General_Carlos_Ibanez_del_Campo',
  'Magallanes_y_Antartica_Chilena',
];

export { region_by_sucursal, regiones_chile, orderRegiones };
