// simplePanel.tsx

import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { GeneralStatusPanel } from '../views/generalStatus/generalStatus'; // Asegúrate de que la ruta sea correcta
import { processData, getRegionStatus, getSucursales, getServicesByHost, getInfoServiceAreas } from 'api/datasource.js';
import './SimplePanel.css';

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: number;
  height: number;
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const [regionStatus, setRegionStatus] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [serviceAreas, setServiceAreas] = useState([]);

  useEffect(() => {
    const series = processData(data, '*', 'json');
    let servicesByHost;
    if (series.services) {
      servicesByHost = getServicesByHost(series.services);
    }
    if (series.hosts) {
      const regionStatus = getRegionStatus(series.hosts);
      
      const sucursales = getSucursales(series.hosts);
      setRegionStatus(regionStatus);
      setSucursales(sucursales);
    }
    if (series.hosts && series.services) {
      const infoServiceAreas = getInfoServiceAreas(series.hosts, servicesByHost);
      setServiceAreas(infoServiceAreas);
    }
  }, [data]);

  // Aquí renderizas GeneralStatusPanel, pasándole las props necesarias
  return (
    <div className="body">
      <GeneralStatusPanel
        options={options}
        data={data}
        width={width}
        height={height}
        sucursales={sucursales}
        regionStatus={regionStatus}
        infoServiceAreas={serviceAreas}
      />
    </div>
  );
};
