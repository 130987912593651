import React, { useEffect, useState, useRef } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ChileMapSvg from './Chile';
import AlertIndicator from './AlertIndicator';
import './GeographicMap.css';

type Props = {
  chooseRegion: any;
  regiones: any;
  info: any;
  regionNumber: any;
  region: any;
  scale: any;
};

const GeographicMap: React.FC<Props> = ({ chooseRegion, regiones, info, regionNumber, region, scale }) => {
  const [alerts, setAlerts] = useState<any>([]);
  let ref = useRef();

  function showPaintRegions() {
    const arrayAlerts = [];
    regiones.map((regItem: any, index: number) => {
      const status = regItem.status;
      const active = regItem.active;
      const hostProblems = regItem.hostProblems;

      let labelStatus = 'ok';
      let size = 24;

      let colorButton = '#D0D3D4';
      if (status === 0 && active) {
        colorButton = '#10D884';
      } else if (status === 1 && active) {
        colorButton = '#EFCB0D';
        size = 28;
        labelStatus = 'warning';
      } else if (status === 2 && active) {
        colorButton = '#ED4C5C';
        size = 32;
        labelStatus = 'critical';
      } else if (status === 3 && active) {
        colorButton = '#FF8B1F';
      }

      const delta = index % 2 === 0 ? -20 : 20;

      let elementImageContainer = document.getElementById('image-container');
      const elementImageContainerPosition = elementImageContainer?.getBoundingClientRect();
      const widthImageContainer = elementImageContainerPosition?.width || 0;
      const topImageContainer = elementImageContainerPosition?.top || 0;

      let element: any = document.getElementById(regItem.region);
      const positionImage = element?.getBoundingClientRect();
      const top = positionImage?.top || 0;
      const left = positionImage?.left || 0;
      const width = positionImage?.width || 0;
      element.style.fill = colorButton;
      element.style.stroke = 'white';
      if (active) {
        arrayAlerts.push(
          <>
            <div
              className={`alerts-geomap-item ${labelStatus}`}
              style={{
                position: 'absolute',
                top: `${(top - topImageContainer) / 1.25}px`,
                left: `${(left - widthImageContainer) / 1.25 + width / 2 + 40 + delta + 410}px`,
                width: `${size}px`,
                height: `${size}px`,
                borderRadius: '50%',
                background: `#ffffff`,
                color: `${colorButton}`,
                border: '1px solid #ffffff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: `${size - 14}px`,
                cursor: active ? 'pointer' : 'not-allowed',
              }}
              data-tooltip-id={`my-tooltip-${index}`}
            >
              {regItem.problems}
            </div>
            <ReactTooltip id={`my-tooltip-${index}`} place="top" variant="info" delayHide={2000}>
              {hostProblems.map((host: any, index: number) => (
                <div key={`tooltip-${index}`}>{host}</div>
              ))}
            </ReactTooltip>
          </>
        );
        element.addEventListener('mouseover', () => {
          element.style.strokeWidth = '4px';
          element.style.stroke = 'white';
          element.style.cursor = 'pointer';
        });
        element.addEventListener('mouseout', () => {
          element.style.fill = colorButton;
          element.style.stroke = 'white';
          element.style.strokeWidth = '1px';
        });
      }
    });
    setAlerts(arrayAlerts);

    const movableElement = document.getElementById('image-container');

    let initialX, initialY;
    let isDragging = false;

    function handleMouseDown(event) {
      isDragging = true;
      initialX = event.clientX - movableElement.offsetLeft;
      initialY = event.clientY - movableElement.offsetTop;
      document.addEventListener('mousemove', handleMouseMove);
    }

    function handleMouseMove(event) {
      if (isDragging) {
        const newX = event.clientX - initialX;
        const newY = event.clientY - initialY;
        movableElement.style.left = newX + 'px';
        movableElement.style.top = newY + 'px';
      }
    }

    function handleMouseUp() {
      isDragging = false;
      document.removeEventListener('mousemove', handleMouseMove);
    }

    movableElement.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      movableElement.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      showPaintRegions();
      // showAlerts();
    });
    observer.observe(ref.current);
    return () => ref.current && observer.unobserve(ref.current);
  }, [ref, regiones]);

  return (
    <section className="region-map-section" ref={ref}>
      <div className="image-container" id="image-container" style={{ transform: `scale(${scale})`, top: '-20px' }}>
        {/* <div className="image-container" id="image-container" style={{ transform: `scale(${scale})` }}> */}
        <ChileMapSvg choose={chooseRegion} regiones={info} />
        {alerts}
      </div>
    </section>
  );
};

export default GeographicMap;
